<template>
  <div>
    <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
      <span class="mask bg-gradient-doc opacity-8"></span>
    </base-header>
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-12">
          <card shadow type="secondary">
            <div slot="header" class="bg-white border-0">
              <div class="row align-items-center">
                <div class="col-8">
                  <h3 class="mb-0">{{$t('opinionPage.title')}}</h3>
                </div>
              </div>
            </div>
            <div v-if="reviews && reviews.length > 0">
              <div class="row mb-5" v-for="(review,i) in reviews" :key="i" style="border-bottom:1px solid rgba(0, 0, 0, 0.1);padding-bottom:20px">
                <label class="col-md-9" style="font-weight: 550"
                  >{{review.userId.firstName}} {{review.userId.lastName}}</label
                >

                <base-button class="col-md-2" style="background-color:#cc000e;border:none"
                @click="deleteReview(review._id)"
                  >{{$t('opinionPage.delete')}}</base-button
                >
                 <div class="col-md-12 mt-3">
                  {{review.comment}}
                </div>
              </div>
            </div>
            <div class="p-4" v-else>
                <h3>Pas d'avis</h3>
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  computed:{
      user(){
          return this.$store.state.doctor.user
      }
  },
  data:()=>({
      reviews:[]
  }),
  methods: {
    async getReviews() {
        try{
            this.reviews = await this.$store.dispatch('doctor/getReviews')
            console.log(this.reviews);
        }
        catch(err){
            console.log(err);
        }
    },
    async deleteReview(id){
        try{
            const res = await this.$store.dispatch('doctor/deleteReview',id)
            console.log(res);
            this.getReviews()
             this.$toast.open({
                message: "Commentaire bien supprimer",
                type: "success",
              });
        }
        catch(err){
            console.log(err);
             this.$toast.open({
                message: "Erreur lors de la suppression",
                type: "error",
              });
        }
    }
  },
  mounted() {
      this.getReviews()
  },
};
</script>
<style>
.bg-gradient-doc {
  background: linear-gradient(87deg, #cc000e 0, #889720 100%) !important;
}
.subject-label {
  color: #82846d;
  font-size: 0.875rem;
  font-weight: 600;
  display: inline-block;
  margin-bottom: 0.5rem;
}
.subject-option {
  display: block;
  width: 100%;
  height: calc(1.5em + 1.25rem + 2px);
  padding: 0.625rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #8898aa;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #cad1d7;
  border-radius: 0.375rem;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
  border: 0;
}
</style>
