<template>
  <div>
    <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
      <span class="mask bg-gradient-doc opacity-8"></span>
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-3 order-xl-2 mb-5 mb-xl-0">
          <div class="card card-profile shadow">
            <div class="row justify-content-center">
              <div class="col-lg-3 order-lg-2">
                <div class="card-profile-image">
                  <a href="#" v-if="!doctor.photo">
                    <img
                      style="background: #fff !important"
                      v-if="doctor.gender == 'homme'"
                      src="https://res.cloudinary.com/dba2dcapc/image/upload/v1611628277/t%C3%A9l%C3%A9chargement_yo46pz.jpg"
                      class="rounded-circle"
                      @click="openUploadModal"
                    />
                    <img
                      style="background: #ffff"
                      v-else
                      src="https://res.cloudinary.com/dba2dcapc/image/upload/v1611690556/2ac5343c-2653-4e20-862a-6205d4e6fa61_lict1f.png"
                      class="rounded-circle"
                      @click="openUploadModal"
                    />
                  </a>
                  <a href="#" v-else>
                    <img
                      style="background: #ffff"
                      :src="`${$store.state.doctor.cloudinaryUrl}${doctor.photo}`"
                      class="rounded-circle"
                      @click="openUploadModal"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div
              class="card-header text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4"
            ></div>
            <div class="card-body" style="margin-top: 17%">
              <div class="text-center">
                <h2>
                  {{ doctor.firstName }} {{ doctor.lastName
                  }}<span class="font-weight-light">{{ doctor.age }}</span>
                </h2>

                <!-- <rate :length="5" v-model="myRate" :disabled="true" /> -->
                <!-- <div class="h5 font-weight-300">
                  <i class="ni location_pin mr-2"></i>{{ doctor.city }}
                </div> -->
                <div class="h5 mt-4">
                  <i class="ni business_briefcase-24 mr-2"></i
                  >{{ doctor.specialty.join(", ") }}
                </div>
                <div class="h5 font-weight-300">
                  <i class="ni location_pin mr-2"></i>{{ doctor.presentation }}
                </div>
                <!-- <div>
                  <i class="ni education_hat mr-2"></i>
                  <p v-for="(education, i) in doctor.education" :key="i">
                    {{ education }}
                  </p>
                </div> -->
                <hr class="my-4" />
                <p>{{ doctor.description }}</p>
                <base-button
                  @click="openUploadModalDocument"
                  style="
                    background-color: #cc000e;
                    border: none;
                    margin-bottom: 10px;
                    width: 100%;
                  "
                  >{{ $t("attrs.document1") }}</base-button
                >
                <base-button
                  @click="openUploadModalDocument"
                  style="
                    background-color: #cc000e;
                    border: none;
                    margin-bottom: 10px;
                    width: 100%;
                  "
                  >{{ $t("attrs.document2") }}</base-button
                >
                <base-button
                  @click="openUploadModalDocument"
                  style="
                    background-color: #cc000e;
                    border: none;
                    margin-bottom: 10px;
                    width: 100%;
                  "
                  >{{ $t("attrs.document3") }}</base-button
                >
                <!-- <p style="font-size: 11px !important; margin-top: 5%">
                  {{$t('doctorProfilePage.documentDescription')}}
                </p> -->
              </div>
            </div>
          </div>
          <base-button
            class="mt-5"
            block
            @click="updateProfile"
            style="background-color: #cc000e; border: none"
            >{{ $t("save") }}</base-button
          >
        </div>
        <div class="col-xl-9 order-xl-1">
          <card shadow type="secondary">
            <div slot="header" class="bg-white border-0">
              <div class="row align-items-center">
                <div class="col-8">
                  <h3 class="mb-0">{{ $t("doctorProfilePage.title") }}</h3>
                </div>
              </div>
            </div>
            <template>
              <form @submit.prevent>
                <h6 class="heading-small text-muted mb-4">
                  {{ $t("doctorProfilePage.labels.doctorInfo") }}
                </h6>
                <div class="pl-lg-4">
                  <div class="row">
                    <div class="col-lg-12">
                      <base-input
                        alternative=""
                        :label="$t('attrs.email')"
                        :placeholder="$t('attrs.email')"
                        input-classes="form-control-alternative"
                        v-model="newDoctor.email"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <base-input
                        alternative=""
                        :label="$t('attrs.name')"
                        :placeholder="$t('attrs.name')"
                        input-classes="form-control-alternative"
                        v-model="newDoctor.firstName"
                      />
                    </div>
                    <div class="col-lg-6">
                      <base-input
                        alternative=""
                        :label="$t('attrs.surname')"
                        :placeholder="$t('attrs.surname')"
                        input-classes="form-control-alternative"
                        v-model="newDoctor.lastName"
                      />
                    </div>
                  </div>
                </div>
                <hr class="my-4" />
                <!-- Address -->
                <h6 class="heading-small text-muted mb-4">
                  {{ $t("doctorProfilePage.labels.personalInfo") }}
                </h6>
                <div class="pl-lg-4">
                  <div class="row">
                    <div class="col-md-6">
                      <base-input
                        alternative=""
                        :label="$t('attrs.adress')"
                        :placeholder="$t('attrs.adress')"
                        input-classes="form-control-alternative"
                        v-model="newDoctor.address"
                      />
                    </div>
                    <div class="col-md-6">
                      <base-input
                        alternative=""
                        type="date"
                        :label="$t('attrs.dateOfBirth')"
                        :placeholder="$t('attrs.dateOfBirth')"
                        input-classes="form-control-alternative"
                        :value="placeholderDate"
                        @input="setDate($event)"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-4">
                      <base-input
                        alternative=""
                        :label="$t('attrs.city')"
                        :placeholder="$t('attrs.city')"
                        input-classes="form-control-alternative"
                        v-model="newDoctor.city"
                      />
                    </div>
                    <div class="col-lg-4">
                      <base-inputoffreYear
                        alternative=""
                        :label="$t('attrs.codePostal')"
                        :placeholder="$t('attrs.codePostal')"
                        input-classes="form-control-alternative"
                        v-model="newDoctor.postalCode"
                      />
                    </div>
                  </div>
                </div>
                <hr class="my-4" />
                <!-- Address -->
                <h6 class="heading-small text-muted mb-4">
                  {{ $t("doctorProfilePage.labels.workInfo") }}
                </h6>
                <div class="pl-lg-4">
                  <div
                    class="row"
                    v-for="(education, i) in newDoctor.education"
                    :key="i"
                  >
                    <div class="col-md-9">
                      <base-input
                        alternative=""
                        :placeholder="$t('attrs.training')"
                        input-classes="form-control-alternative"
                        v-model="newDoctor.education[i]"
                      />
                    </div>
                    <div
                      class="col-md-1"
                      v-if="i == newDoctor.education.length - 1"
                    >
                      <base-button
                        style="border: none"
                        type="info"
                        @click="addField(newDoctor.education, '')"
                        icon="ni ni-fat-add"
                      ></base-button>
                    </div>
                    <div class="col-md-1" v-if="i > 0">
                      <base-button
                        style="background-color: #cc000e; border: none"
                        @click="removeField(newDoctor.education, i)"
                        icon="ni ni-fat-delete"
                      ></base-button>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-4">
                      <label style="font-weight: 550">{{
                        $t("attrs.specialties")
                      }}</label>
                      <multiselect
                        :multiple="true"
                        :placeholder="$t('attrs.specialties')"
                        v-model="newDoctor.specialty"
                        :options="getSpecialties()"
                      ></multiselect>
                    </div>
                    <div class="col-lg-4">
                      <label style="font-weight: 550">{{
                        $t("attrs.spokenLanguages")
                      }}</label>
                      <multiselect
                        :multiple="true"
                        :placeholder="$t('attrs.spokenLanguages')"
                        v-model="newDoctor.spokenLanguage"
                        :options="languages"
                      ></multiselect>
                    </div>
                    <div class="col-lg-4">
                      <label style="font-weight: 550">{{
                        $t("attrs.consultationType")
                      }}</label
                      ><br />
                      <input
                        id="cabinet"
                        type="checkbox"
                        name="consultationType"
                        value="cabinet"
                        v-model="newDoctor.consultationType"
                        style="margin-right: 5px"
                      />
                      <label for="cabinet">{{
                        $t("doctorProfilePage.away")
                      }}</label>
                      <br />
                      <input
                        type="checkbox"
                        id="domicile"
                        name="consultationType"
                        value="domicile"
                        style="margin-right: 5px"
                        v-model="newDoctor.consultationType"
                      />
                      <label for="domicile">{{
                        $t("doctorProfilePage.home")
                      }}</label>
                    </div>
                  </div>
                  <div class="mt-5">
                    <div class="row">
                      <label class="col-3" style="font-weight: 550">{{
                        $t("doctorProfilePage.labels.workSchedule")
                      }}</label>
                      <label class="col-3 offset-2" style="font-weight: 550">{{
                        $t("attrs.morningSchedule")
                      }}</label>
                      <label class="col-3" style="font-weight: 550">{{
                        $t("attrs.eveningSchedule")
                      }}</label>
                    </div>
                    <div
                      class="row"
                      v-for="(day, i) in newDoctor.workSchedule"
                      :key="i"
                    >
                      <div class="col-md-3">
                        <base-input :value="$t(`days.${days[i]}`)" disabled />
                      </div>
                      <div class="col-2">
                        <p>{{ $t("start") }} :</p>
                        <br />
                        <p>{{ $t("end") }} :</p>
                      </div>
                      <div class="col-md-3">
                        <div class="">
                          <base-input
                            type="time"
                            alternative=""
                            input-classes="form-control-alternative"
                            v-model="newDoctor.workSchedule[i].morning.start"
                          />
                        </div>
                        <div class="">
                          <base-input
                            type="time"
                            alternative=""
                            input-classes="form-control-alternative"
                            v-model="newDoctor.workSchedule[i].morning.end"
                          />
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="">
                          <base-input
                            type="time"
                            alternative=""
                            input-classes="form-control-alternative"
                            v-model="newDoctor.workSchedule[i].evening.start"
                          />
                        </div>
                        <div class="">
                          <base-input
                            type="time"
                            alternative=""
                            input-classes="form-control-alternative"
                            v-model="newDoctor.workSchedule[i].evening.end"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mt-5">
                    <label style="font-weight: 550">{{
                      $t("attrs.socialLinks")
                    }}</label>
                    <div
                      class="row"
                      v-for="(socialMedia, i) in newDoctor.socialNetworks"
                      :key="i"
                    >
                      <div class="col-md-4">
                        <base-input>
                          <select
                            class="form-control"
                            v-model="
                              newDoctor.socialNetworks[i].socialNetworkName
                            "
                          >
                            <option value="empty" disabled>
                              {{ $t("doctorProfilePage.socialMediaName") }}
                            </option>
                            <option value="facebook">facebook</option>
                            <option value="twitter">twitter</option>
                            <option value="linkedIn">linkedIn</option>
                            <option value="WhatsApp">WhatsApp</option>
                          </select>
                        </base-input>
                      </div>
                      <div class="col-md-5">
                        <base-input
                          alternative=""
                          :placeholder="$t('doctorProfilePage.profileLink')"
                          input-classes="form-control-alternative"
                          v-model="newDoctor.socialNetworks[i].profileLink"
                        />
                      </div>
                      <div
                        class="col-md-1"
                        v-if="i == newDoctor.socialNetworks.length - 1"
                      >
                        <base-button
                          style="border: none"
                          type="info"
                          @click="
                            addField(newDoctor.socialNetworks, {
                              socialNetworkName: 'empty',
                              profileLink: '',
                            })
                          "
                          icon="ni ni-fat-add"
                        ></base-button>
                      </div>
                      <div class="col-md-1" v-if="i > 0">
                        <base-button
                          style="background-color: #cc000e; border: none"
                          @click="removeField(newDoctor.socialNetworks, i)"
                          icon="ni ni-fat-delete"
                        ></base-button>
                      </div>
                    </div>
                  </div>
                </div>
                <hr class="my-4" />
                <!-- Description -->
                <h6 class="heading-small text-muted mb-4">
                  {{ $t("doctorProfilePage.labels.description") }}
                </h6>
                <div class="pl-lg-4">
                  <div class="form-group">
                    <base-input alternative="">
                      <textarea
                        rows="4"
                        class="form-control form-control-alternative"
                        :placeholder="$t('attrs.presentation') + '...'"
                        v-model="newDoctor.presentation"
                      ></textarea>
                    </base-input>
                  </div>
                </div>
                <div>
                  <h6 class="heading-small text-muted mb-4">
                    {{ $t("doctorProfilePage.labels.location") }}
                  </h6>
                  <!-- <input id="searchTextField" /> -->
                  <div id="map" style="height: 500px"></div>
                </div>
                <modal :show.sync="showModal">
                  <h3>
                    {{ $t("doctorProfilePage.locationPopup.confirmation") }}
                  </h3>

                  <template slot="footer">
                    <base-button
                      style="background-color: #cc000e; border: none"
                      class="ml-auto"
                      @click="showModal = false"
                      >{{
                        $t("doctorProfilePage.locationPopup.buttons[1]")
                      }}</base-button
                    >
                    <base-button
                      style="border: none"
                      type="success"
                      @click="savePosition"
                      >{{
                        $t("doctorProfilePage.locationPopup.buttons[0]")
                      }}</base-button
                    >
                  </template>
                </modal>
              </form>
            </template>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
export default {
  name: "user-profile",
  components: {
    Multiselect,
  },
  data() {
    return {
      days: ["sun", "mon", "tue", "wed", "thi", "fri", "sat"],
      placeholderDate: null,
      publicId: null,
      url: null,
      specialties: [
        { name: "Radiologue" },
        { name: "Psychiatre" },
        { name: "Oncologue" },
        { name: "Endocrinologue" },
        { name: "Dermatologue et vénérologue" },
        { name: "Ostéopathe" },
        { name: "Allergologue" },
        { name: "ORL" },
        { name: "Podologue" },
        { name: "Dentiste" },
        { name: "Rhumatologue" },
        { name: "Urologue" },
        { name: "Psychologue" },
        { name: "Neurologue" },
        { name: "Masseur-kinésithérapeute" },
        { name: "Diététicien" },
        { name: "Cardiologue" },
        { name: "Chirurgien Orthopediste" },
        { name: "Gynécologue" },
        { name: "Chirurgien-dentiste" },
        { name: "Médecin géneraliste" },
        { name: "Pédiatre" },
        { name: "Gynécologue médical et obstérique" },
        { name: "Ophtalmologue" },
        { name: "Pédicure-podologue" },
        { name: "Chirurgien urologue" },
        { name: "Stomatologue" },
        { name: "Chirurgien orthopédiste" },
        { name: "Neurologue" },
        { name: "Gastro-entérologue et hépatologue" },
        { name: "Médecin nutritionniste" },
        { name: "Médecin réanimateur" },
        { name: "Médecin urgentiste" },
        { name: "Néphrologue" },
        { name: "Neurochirurgien" },
        { name: "Neuro-psychiatre" },
        { name: "Onco-radiothérapeute" },
        { name: "Orthodontiste" },
        { name: "Orthophoniste" },
        { name: "Orthoprothésiste" },
        { name: "Orthoptiste" },
        { name: "Phébologue" },
        { name: "Pneumologue" },
        { name: "Psychiatre de l'enfant et de l'adolescent" },
        { name: "Psychomotricien" },
        { name: "Psychothérapeute" },
        { name: "Radiothérapeute" },
      ],
      languages: ["Français", "Anglais", "Arabe"],
      newDoctor: null,
      map: null,
      showModal: false,
      position: null,
      // myRate: 0,
    };
  },
  computed: {
    doctor() {
      let user = this.$store.state.doctor.user;
      user = this.checkForEmptyFields(user);
      // this.myRate = this.rateDocButton();
      this.newDoctor = { ...user };
      return user;
    },
  },
  methods: {
    // async rateDocButton() {
    //   try {
    //     let res = await this.$store.dispatch(
    //       "doctor/getRateDoctor",
    //       this.newDoctor
    //     );
    //     this.myRate = res;
    //     console.log(res);
    //   } catch (err) {
    //     console.log(err);
    //   }
    // },
    initMap() {
      let position = null;
      if (this.newDoctor.position)
        position = {
          lat: parseFloat(this.newDoctor.position.lat),
          lng: parseFloat(this.newDoctor.position.lng),
        };
      let myLatlng = position || {
        lat: 30.87587185903049,
        lng: 13.190554884293533,
      };
      const map = new google.maps.Map(document.getElementById("map"), {
        zoom: 5,
        center: myLatlng,
      });

      let marker = null;
      let infoWindow = null;
      // Create the initial InfoWindow.
      if (!position) {
        infoWindow = new google.maps.InfoWindow({
          content: "Appuiez sur une zone pour enregistrer votre position",
          position: myLatlng,
        });
        infoWindow.open(map);
      } else {
        marker = new google.maps.Marker({
          position: myLatlng,
        });
        marker.setMap(map);
      }

      // Configure the click listener.
      map.addListener("click", (mapsMouseEvent) => {
        // Close the current InfoWindow.
        infoWindow && infoWindow.close();
        if (marker) marker.setMap(null);
        this.position = mapsMouseEvent.latLng;
        // Create a new InfoWindow.
        marker = new google.maps.Marker({
          position: this.position,
        });
        marker.setMap(map);
        this.showModal = true;
      });
    },
    savePosition() {
      this.newDoctor.position = this.position.toJSON();
      this.showModal = false;
      this.$toast.open({
        message: "Position bien enregistré",
        type: "success",
      });
    },

    checkForEmptyFields(user) {
      if (user) {
        if (!user.education || user.education.length == 0)
          user.education = [""];
        if (!user.specialty || user.specialty.length == 0)
          user.specialty = [""];
        if (!user.socialNetworks || user.socialNetworks.length == 0)
          user.socialNetworks = [
            { socialNetworkName: "empty", profileLink: "" },
          ];
        user.workSchedule = this.getWorkDays(user);
      }
      return user;
    },

    getWorkDays(user) {
      return this.days.map((day, i) => {
        const currentDay = user.workSchedule.find((sc) => sc.day == i);
        if (!!currentDay) return currentDay;
        else
          return {
            day: i,
            morning: { start: null, end: null },
            evening: { start: null, end: null },
          };
      });
    },
    updateDate() {
      var dateUser = this.newDoctor.dateOfBirth
        ? new Date(this.newDoctor.dateOfBirth)
        : null;
      if (!dateUser) return null;
      var day = dateUser.getDate();
      var month = dateUser.getMonth() + 1;
      var years = dateUser.getFullYear();
      this.placeholderDate =
        years + "-" + this.filterDate(month) + "-" + this.filterDate(day);
    },

    setDate(e) {
      this.newDoctor.dateOfBirth = e;
      this.updateDate();
    },

    filterDate(valueToChange) {
      if (valueToChange >= 0 && valueToChange < 9) {
        valueToChange = "0" + valueToChange.toString();
      }
      return valueToChange;
    },

    openUploadModal() {
      window.cloudinary
        .openUploadWidget(
          { cloud_name: "dba2dcapc", upload_preset: "upzzyy75" },
          (error, result) => {
            if (!error && result && result.event === "success") {
              console.log("Done uploading..: ", result);
              this.url = result.info.url;
              this.publicId = result.info.public_id;
              this.newDoctor.photo = this.publicId;
            }
          }
        )
        .open();
    },
    openUploadModalDocument() {
      window.cloudinary
        .openUploadWidget(
          { cloud_name: "dba2dcapc", upload_preset: "upzzyy75" },
          (error, result) => {
            if (!error && result && result.event === "success") {
              console.log("Done uploading..: ", result);
              this.url = result.info.url;
              this.publicId = result.info.public_id;
              this.newDoctor.document.push(this.publicId);
            }
          }
        )
        .open();
    },
    addField(array, field) {
      array.push(field);
    },
    removeField(array, i) {
      array.splice(i, 1);
    },

    validateBeforeUpdate() {
      this.newDoctor.socialNetworks = this.newDoctor.socialNetworks.filter(
        (net) => net.socialNetworkName != "empty" && net.profileLink.length > 0
      );
      this.newDoctor.education = this.newDoctor.education.filter(
        (ed) => ed.length > 0
      );
      this.newDoctor.workSchedule = this.newDoctor.workSchedule.filter(
        (sc) =>
          Object.values(sc.morning).every((x) => x) ||
          Object.values(sc.evening).every((x) => x)
      );
    },

    async updateProfile() {
      this.validateBeforeUpdate();
      try {
        let res = await this.$store.dispatch(
          "doctor/updateProfile",
          this.newDoctor
        );
        console.log(res);
        this.$toast.open({
          message: "Profile a été modifié",
          type: "success",
        });
      } catch (err) {
        console.log(err);
        this.$toast.open({
          message: "Erreur lors de la modification",
          type: "error",
        });
      }
    },

    getSpecialties() {
      return this.specialties.map((op) => op.name);
    },
  },

  async mounted() {
    setTimeout(() => {
      this.initMap();
      this.updateDate();
    }, 250);
  },
};
</script>
<style>
.bg-gradient-doc {
  background: linear-gradient(87deg, #cc000e 0, #889720 100%) !important;
}
.multiselect__tag {
  background: #82846d;
}
.multiselect__tag-icon:after {
  color: #cc000e;
}
</style>
