<template>
    <div>
        <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
            <!-- Card stats -->
            <div class="row">
                <!-- <div class="col-xl-4 col-lg-4">
                    <stats-card title="Rendez-vous en attente"
                                type="gradient-orange"
                                sub-title="12"
                                icon="ni ni-chart-bar-32"
                                class="mb-4 mb-xl-0">

                        <template slot="footer">
                            <span class="text-success mr-2"><i class="fa fa-arrow-up"></i> 12.18%</span>
                            <span class="text-nowrap">Depuis le mois dernier</span>
                        </template>
                    </stats-card>
                </div>
                <div class="col-xl-4 col-lg-4">
                    <stats-card title="Rendez-vous acceptés"
                                type="gradient-green"
                                sub-title="31"
                                icon="ni ni-chart-bar-32"
                                class="mb-4 mb-xl-0">

                        <template slot="footer">
                            <span class="text-danger mr-2"><i class="fa fa-arrow-down"></i> 5.72%</span>
                            <span class="text-nowrap">Depuis le mois dernier</span>
                        </template>
                    </stats-card>

                </div>
                <div class="col-xl-4 col-lg-4">
                    <stats-card title="Rendez-vous refusés"
                                type="gradient-info"
                                sub-title="2"
                                icon="ni ni-chart-bar-32"
                                class="mb-4 mb-xl-0">

                        <template slot="footer">
                            <span class="text-success mr-2"><i class="fa fa-arrow-up"></i> 54.8%</span>
                            <span class="text-nowrap">Depuis le mois dernier</span>
                        </template>
                    </stats-card>
                </div> -->
            </div>
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col">
                    <appointments-table :title="$t('appointmentsPage.tableTitles.pendingAppointments')" :data="pendingAppointments" @refresh="getAppointments" type="hold"></appointments-table>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col">
                    <appointments-table :title="$t('appointmentsPage.tableTitles.acceptedAppointments')" :data="acceptedAppointments" @refresh="getAppointments" type="accepted"></appointments-table>
                </div>
            </div>
              <div class="row mt-5">
                <div class="col">
                    <appointments-table :title="$t('appointmentsPage.tableTitles.declinedAppointments')" :data="declinedAppointments" @refresh="getAppointments" type="refused"></appointments-table>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
  import AppointmentsTable from './Tables/AppointmentsTable'
  export default {
    name: 'tables',
    components: {
      AppointmentsTable
    },
    data(){
        return{
            pendingAppointments:[],
            acceptedAppointments:[],
            declinedAppointments:[]
        }
    },
    methods:{
        async getAppointments(){
            try{
                const appointments = await this.$store.dispatch("doctor/getAppointments")
                console.log(appointments);
                this.pendingAppointments = this.getCorrespondingAppointments('pending',appointments)
                this.acceptedAppointments = this.getCorrespondingAppointments('accepted',appointments)
                this.declinedAppointments = this.getCorrespondingAppointments('declined',appointments)
            }
            catch(err){
                console.log(err);
            }
        },
        getCorrespondingAppointments(type,appointments){
            return appointments.filter(app => app.state == type)
            .map(app => app.guestId ? {...app,patientId:app.guestId} : app)
        }
    },
    mounted(){
        this.getAppointments()
    }
  };
</script>
<style></style>
