<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <!-- Card stats -->
      <div class="row">
        <!-- <div class="col-xl-4 col-lg-4">
          <stats-card
            title="Rendez-vous en attente"
            type="gradient-orange"
            sub-title="12"
            icon="ni ni-chart-bar-32"
            class="mb-4 mb-xl-0"
          >
            <template slot="footer">
              <span class="text-success mr-2"
                ><i class="fa fa-arrow-up"></i> 12.18%</span
              >
              <span class="text-nowrap">Depuis le mois dernier</span>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-4 col-lg-4">
          <stats-card
            title="Rendez-vous acceptés"
            type="gradient-green"
            sub-title="31"
            icon="ni ni-chart-bar-32"
            class="mb-4 mb-xl-0"
          >
            <template slot="footer">
              <span class="text-danger mr-2"
                ><i class="fa fa-arrow-down"></i> 5.72%</span
              >
              <span class="text-nowrap">Depuis le mois dernier</span>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-4 col-lg-4">
          <stats-card
            title="Rendez-vous refusés"
            type="gradient-info"
            sub-title="2"
            icon="ni ni-chart-bar-32"
            class="mb-4 mb-xl-0"
          >
            <template slot="footer">
              <span class="text-success mr-2"
                ><i class="fa fa-arrow-up"></i> 54.8%</span
              >
              <span class="text-nowrap">Depuis le mois dernier</span>
            </template>
          </stats-card>
        </div> -->
      </div>
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">
          <patients-table title="Patients" :data="patients"></patients-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PatientsTable from "./Tables/ProjectsTablePatient/index.vue";
export default {
  name: "tables",
  components: {
    PatientsTable,
  },
  data() {
    return {
      patients: [],
    };
  },
  methods: {
    async getPatients() {
      try {
        this.patients = await this.$store.dispatch("doctor/getPatients");
        console.log(this.patients);
      } catch (err) {
        console.log(err);
      }
    },
  },
  mounted(){
    this.getPatients()
  }
};

</script>
<style></style>
