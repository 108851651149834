<template>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
            {{ title }}
          </h3>
        </div>
        <!-- <div class="col text-right">
        <input class="form-control" placeholder="Recherche" />
      </div> -->
      </div>
    </div>

    <div class="table-responsive">
      <base-table
        class="table table-flush"
        :class="type === 'dark' ? 'table-dark' : ''"
        :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
        tbody-classes="list"
        :data="data"
        style="min-height: 25vh"
        v-if="data && data.length > 0"
      >
        <template slot="columns">
          <th class="text-center">{{ $t("patientsPage.tableFields.name") }}</th>
          <th class="text-center">{{ $t("patientsPage.tableFields.sexe") }}</th>
          <th class="text-center">
            {{ $t("patientsPage.tableFields.phone") }}
          </th>
          <th class="text-center">
            {{ $t("patientsPage.tableFields.email") }}
          </th>
          <th class="text-center">Age</th>
          <th></th>
        </template>

        <template v-slot="{ row }">
          <td class="text-center">
            {{ row.user.firstName }} {{ row.user.lastName }}
          </td>

          <td class="text-center">
            {{ row.user.gender }}
          </td>

          <td class="text-center">
            {{ row.user.phoneNumber }}
          </td>
          <td class="text-center">
            {{ row.user.email }}
          </td>
          <td class="text-center">
            {{ calcAge(row.user.dateOfBirth) }}
          </td>
          <td class="text-center">
            <base-dropdown class="dropdown" position="right">
              <a
                slot="title"
                class="btn btn-sm btn-icon-only text-light"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="fas fa-ellipsis-v"></i>
              </a>

              <template>
                <a
                  class="dropdown-item"
                  href="#"
                  @click="openPatientFile(row)"
                  >{{ $t("patientsPage.tableActions.patientFile") }}</a
                >
                <!-- <a class="dropdown-item" href="#">{{
                  $t("patientsPage.tableActions.update")
                }}</a>
                <a class="dropdown-item" href="#">{{
                  $t("patientsPage.tableActions.delete")
                }}</a> -->
              </template>
            </base-dropdown>
          </td>
        </template>
      </base-table>
      <div v-else class="p-4">
        <h3>{{ $t("patientsPage.empty") }}</h3>
      </div>
    </div>

    <modal :show.sync="modals.modal" v-if="file">
      <h2 slot="header" class="modal-title" id="modal-title-default">
        {{ $t("patientFile.title") }}
      </h2>
      <div class="row">
        <div class="col-lg-4">
          <base-input
            alternative=""
            :label="$t('attrs.name')"
            :placeholder="$t('attrs.name')"
            disabled
            :value="file.userId.lastName"
            input-classes="form-control-alternative"
          />
        </div>
        <div class="col-lg-4">
          <base-input
            alternative=""
            :label="$t('attrs.surname')"
            :placeholder="$t('attrs.surname')"
            disabled
            :value="file.userId.firstName"
            input-classes="form-control-alternative"
          />
        </div>
        <div class="col-lg-4">
          <base-input
            alternative=""
            :label="$t('attrs.phone')"
            :placeholder="$t('attrs.phone')"
            disabled
            :value="file.userId.phoneNumber"
            input-classes="form-control-alternative"
          />
        </div>
        <div class="col-lg-4">
          <base-input
            alternative=""
            :label="$t('attrs.email')"
            :placeholder="$t('attrs.email')"
            disabled
            :value="file.userId.email"
            input-classes="form-control-alternative"
          />
        </div>
        <div class="col-lg-4">
          <base-input
            alternative=""
            :label="$t('attrs.gender')"
            :placeholder="$t('attrs.gender')"
            disabled
            :value="file.userId.gender"
            input-classes="form-control-alternative"
          />
        </div>
        <div class="col-lg-4">
          <base-input
            alternative=""
            :label="$t('attrs.dateOfBirth')"
            :placeholder="$t('attrs.dateOfBirth')"
            disabled
            :value="getDate(file.userId.dateOfBirth)"
            input-classes="form-control-alternative"
          />
        </div>
      </div>
      <div class="row">
        <table class="styled-table">
          <thead>
            <tr>
              <th>{{ $t("patientFile.tableFields.appointmentNumber") }}</th>
              <th>{{ $t("patientFile.tableFields.date") }}</th>
              <th>{{ $t("patientFile.tableFields.description") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(rdv, index) in filteredAppointments" :key="index">
              <td>{{ index + 1 }}</td>
              <td>{{ getDate(rdv.idAppointment.date.day) }}</td>
              <td>
                <base-input
                  alternative=""
                  input-classes="form-control-alternative"
                >
                  <textarea
                    rows="4"
                    :disabled="!editing"
                    :placeholder="$t('patientFile.tableFields.description')"
                    class="form-control form-control-alternative"
                    v-model="rdv.description"
                  ></textarea>
                </base-input>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="row" style="width: 100%">
          <div class="col-lg-6">
            <base-button
              type=""
              size="sm"
              style="
                width: 100%;
                background: #cc000e;
                color: #fff;
                margin-top: 4%;
                margin-left: 4%;
              "
              @click="edit"
            >
              {{ $t("patientFile.buttons.update") }}
            </base-button>
          </div>
          <div class="col-lg-6">
            <base-button
              type=""
              size="sm"
              style="
                width: 100%;
                background: #cc000e;
                color: #fff;
                margin-top: 4%;
                margin-left: 4%;
              "
              @click="updateFile(file._id)"
            >
              {{ $t("patientFile.buttons.save") }}
            </base-button>
          </div>
        </div>
      </div>
      <hr style="height: 1px; background: #cc000e" />
      <div class="row" style="margin-top: 4%; margin-bottom: 4%">
        <div class="col-lg-3 col-md-4" v-for="(img, i) in file.images" :key="i">
          <img
            :src="imgFile(img)"
            :alt="img"
            style="height: 100%; width: 100%; padding: 5%"
          />
          <base-button
            @click="deleteImageFile(img)"
            type=""
            size="sm"
            style="
              width: 100%;
              background: #cc000e;
              color: #fff;
              margin-top: 4%;
            "
          >
            <!-- {{ $t("patientFile.buttons.delete") }} -->
            Supprimer
          </base-button>
        </div>
      </div>

      <div class="row" style="width: 100%">
        <div class="col-lg-6">
          <base-button
            type=""
            size="sm"
            style="
              width: 100%;
              background: #cc000e;
              color: #fff;
              margin-top: 4%;
              margin-left: 4%;
            "
            @click="openUploadModal()"
            >{{ $t("patientFile.buttons.upload") }}
          </base-button>
        </div>
        <div class="col-lg-6">
          <base-button
            type=""
            size="sm"
            style="
              width: 100%;
              background: #cc000e;
              color: #fff;
              margin-top: 4%;
              margin-left: 4%;
            "
            @click="saveImgFile()"
            >Enregistrer
          </base-button>
        </div>
      </div>

      <hr style="height: 1px; background: #cc000e" />
      <div>
        {{ $t("patientFile.instructions.title") }}
      </div>
      <ol>
        <li v-for="i in 3" :key="i">
          {{ $t(`patientFile.instructions.steps[${i - 1}]`) }}
        </li>
      </ol>

      <div class="row">
        <div class="col-lg-12 text-right btn-table-blog">
          <base-button
            @click="modals.modal = false"
            type=""
            size="sm"
            v-print="'#fiche'"
            style="width: 100%; background: #cc000e; color: #fff"
            >{{ $t("patientFile.buttons.print") }}
          </base-button>
        </div>
      </div>
    </modal>

    <div id="fiche" v-if="file">
      <img
        src="../img.png"
        alt=""
        style="
          height: 10%;
          width: 10%;
          display: block;
          margin-left: auto;
          margin-right: auto;
          margin-bottom: 2%;
        "
      />
      <h2 id="pdfTitle">{{ $t("patientFile.title") }}</h2>
      <table id="rdv">
        <tr>
          <td>
            <b>{{ $t("attrs.surname") }}</b>
          </td>
          <td>
            {{ file.userId.firstName }}
            <div style="width: 100%; border-style: dotted none none none"></div>
          </td>
          <td>
            <b>{{ $t("attrs.name") }}</b>
          </td>
          <td>
            {{ file.userId.lastName }}
            <div style="width: 100%; border-style: dotted none none none"></div>
          </td>
        </tr>
        <tr>
          <td>
            <b>{{ $t("attrs.dateOfBirth") }}</b>
          </td>
          <td>
            {{ getDate(file.userId.dateOfBirth) }}
            <div style="width: 100%; border-style: dotted none none none"></div>
          </td>
          <td>
            <b>{{ $t("attrs.phone") }}</b>
          </td>
          <td>
            {{ file.userId.phoneNumber }}
            <div style="width: 100%; border-style: dotted none none none"></div>
          </td>
        </tr>
        <tr>
          <td>
            <b>{{ $t("attrs.email") }}</b>
          </td>
          <td>
            {{ file.userId.email }}
            <div style="width: 100%; border-style: dotted none none none"></div>
          </td>
          <td>
            <b>{{ $t("attrs.gender") }}</b>
          </td>
          <td>
            {{ file.userId.gender }}
            <div style="width: 100%; border-style: dotted none none none"></div>
          </td>
        </tr>
      </table>

      <table id="styled-table">
        <thead>
          <tr>
            <th>
              <b>{{ $t("patientFile.tableFields.appointmentNumber") }}</b>
            </th>
            <th>
              <b>{{ $t("patientFile.tableFields.date") }}</b>
            </th>
            <th>
              <b>{{ $t("patientFile.tableFields.description") }}</b>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(rdv, index) in filteredAppointments" :key="index">
            <td>{{ index + 1 }}</td>
            <td>{{ getDate(rdv.idAppointment.date.day) }}</td>
            <td v-if="rdv.description">{{ rdv.description }}</td>
            <td v-else>{{ $t("patientFile.emptyDescription") }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- <div class="card-footer d-flex justify-content-end" :class="type === 'dark' ? 'bg-transparent' : ''">
    <base-pagination></base-pagination>
  </div> -->
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "projects-table-patient",
  props: {
    data: Array,
    type: {
      type: String,
    },
    title: String,
  },
  data() {
    return {
      verif: false,
      modals: {
        modal: false,
        pdf: false,
      },
      file: null,
      appointments: [],
      filteredAppointments: [],
      editing: false,
      publicId: "",
    };
  },
  methods: {
    calcAge(dateOfBirth) {
       var diff = new Date() - new Date(dateOfBirth);
        var age = new Date(diff);
        return Math.abs(age.getUTCFullYear() - 1970);
    },
    imgFile(img) {
      return (
        "https://res.cloudinary.com/dba2dcapc/image/upload/v1611354830/" + img
      );
    },
    deleteImageFile(img) {
      this.file.images.splice(this.file.images.indexOf(img), 1);
    },
    openUploadModal() {
      window.cloudinary
        .openUploadWidget(
          { cloud_name: "dba2dcapc", upload_preset: "upzzyy75" },
          (error, result) => {
            if (!error && result && result.event === "success") {
              console.log("Done uploading..: ", result);
              this.url = result.info.url;
              this.publicId = result.info.public_id;
              this.file.images.push(this.publicId);
            }
          }
        )
        .open();
    },
    saveImgFile() {
      axios
        .put(
          "https://algedoc-backend.herokuapp.com/api/patientFiles/" +
            this.file._id,
          {
            images: this.file.images,
          }
        )
        .then((response) => {
          this.$toast.open({
            message: "Fiche a été bien modifié",
            type: "success",
          });
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        });
    },
    async openPatientFile({ user }) {
      try {
        this.file = await this.$store.dispatch(
          "doctor/getPatientFile",
          user._id
        );
        if (this.file.guestId) this.file.userId = this.file.guestId;
        this.appointments = this.file.appointmentDetail;
        this.filteredAppointments = this.filterAppointments(
          this.file.appointmentDetail
        );
        console.log(this.filteredAppointments, this.file);
        setTimeout(() => {
          this.modals.modal = true;
        }, 0);
      } catch (err) {
        console.log(err);
      }
    },
    filterAppointments(array) {
      return array
        .filter((app) => new Date(app.idAppointment.date.day) < new Date())
        .reverse();
    },
    edit() {
      this.editing = !this.editing;
    },
    getDate(date) {
      if (!date) return "";
      return new Date(date).toLocaleDateString();
    },

    async updateFile(fileId) {
      let appointmentsToUpdate = this.appointments.map((app) => {
        let appointment = this.filteredAppointments.find(
          (ap) => ap.idAppointment._id == app.idAppointment._id
        );
        if (!!appointment) return appointment;
        else return app;
      });
      const dataToUpdate = {
        fileId,
        appointmentDetail: appointmentsToUpdate,
      };
      try {
        const res = await this.$store.dispatch(
          "doctor/updatePatientFile",
          dataToUpdate
        );
        console.log(res);
        this.$toast.open({
          message: "Fiche a été bien modifié",
          type: "success",
        });
      } catch (err) {
        console.log(err);
        this.$toast.open({
          message: "Erreur lors de la modification",
          type: "error",
        });
      }
    },
  },
};
</script>
<style lang="css" src="./index.css"></style>
