<template>
  <div class="row justify-content-center">
    <div class="col-lg-5 col-md-7">
      <div class="card bg-secondary shadow border-0">
        <div class="card-header bg-transparent">
          <div class="text-muted text-center mt-2">
            <h2 style="color: #cc000e !important">Se connecter</h2>
          </div>
          <!-- <div class="btn-wrapper text-center">
                            <a href="#" class="btn btn-neutral btn-icon">
                                <span class="btn-inner--icon"><img src="img/icons/common/github.svg"></span>
                                <span class="btn-inner--text">Github</span>
                            </a>
                            <a href="#" class="btn btn-neutral btn-icon">
                                <span class="btn-inner--icon"><img src="img/icons/common/google.svg"></span>
                                <span class="btn-inner--text">Google</span>
                            </a>
                        </div> -->
        </div>
        <div class="card-body px-lg-5 py-lg-5">
          <!-- <div class="text-center text-muted mb-4">
                            <small>Or sign in with credentials</small>
                        </div> -->
          <form role="form">
            <base-input
              class="input-group-alternative mb-3"
              placeholder="Email"
              addon-left-icon="ni ni-email-83"
              v-model="model.email"
            >
            </base-input>

            <base-input
              class="input-group-alternative"
              placeholder="Mot de passe"
              type="password"
              addon-left-icon="ni ni-lock-circle-open"
              v-model="model.password"
            >
            </base-input>

            <!-- <base-checkbox class="custom-control-alternative">
                                <span class="text-muted">Remember me</span>
                            </base-checkbox> -->
            <div class="text-center">
              <base-button
                style="background: #cc000e !important; border: none"
                class="my-4"
                @click="login"
                >Se connecter</base-button
              >
            </div>
          </form>
        </div>
      </div>
      <div class="row mt-3">
        <!-- <div class="col-6">
                        <a href="#" class="text-light"><small>Forgot password?</small></a>
                    </div>
                    <div class="col-6 text-right">
                        <router-link to="/register" class="text-light"><small>Create new account</small></router-link>
                    </div> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "login",
  data() {
    return {
      model: {
        email: "",
        password: "",
      },
    };
  },
  methods: {
    initSocket() {
      this.$store.dispatch("socket/connectSocket");
      this.$store.state.socket.socket.on("appointmentReceived", (notif) => {
        console.log("appointmentReceived");
        this.$toast.open({
          message: notif.description,
          type: "success",
          // position: "top-right",
        });
      });
    },
    async login() {
      try {
        const res = await this.$store.dispatch("doctor/login", this.model);
        console.log(res);
          await this.$router.replace("/dashboard");
          this.$toast.open({
            message: "Docteur : Vous êtes connecté",
            type: "success",
          });
          this.initSocket()
      } catch (err) {
        console.log(err);
        this.$toast.open({
          message: "Docteur : Email ou mot de passe éronné",
          type: "error",
        });
      }
    },
  },
};
</script>
<style>
</style>
