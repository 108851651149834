<template>
    <div>
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
                   >
            <span class="mask bg-gradient-doc opacity-8"></span>
        </base-header>
        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col-xl-12 ">
                    <card shadow type="secondary">
                        <div slot="header" class="bg-white border-0">
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <h3 class="mb-0">{{$t('contactsPage.title')}}</h3>
                                </div>
                            </div>
                        </div>
                        <template>
                            <form @submit.prevent>
                                <div class="pl-lg-4">
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <base-input alternative=""
                                                        :label="$t('contactsPage.labels.name')"
                                                        :placeholder="$t('contactsPage.labels.name')"
                                                        input-classes="form-control-alternative"
                                                        v-model="model.username"
                                            />
                                        </div>
                                        <div class="col-lg-6">
                                            <base-input alternative=""
                                                        :label="$t('contactsPage.labels.email')"
                                                        :placeholder="$t('contactsPage.labels.email')"
                                                        input-classes="form-control-alternative"
                                                        v-model="model.email"
                                            />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <base-input alternative=""
                                                        :label="$t('contactsPage.labels.phone')"
                                                        :placeholder="$t('contactsPage.labels.phone')"
                                                        input-classes="form-control-alternative"
                                                        v-model="model.phone"
                                            />
                                        </div>
                                        <div class="col-lg-6">
                                            <!-- <base-input alternative=""
                                                        label="Sujet"
                                                        placeholder="Sujet"
                                                        input-classes="form-control-alternative"
                                                        v-model="model.lastName"
                                            /> -->
                                            <label for="" class="subject-label">{{$t('contactsPage.labels.subject')}}</label>
                                            <select name="" id="" class="subject-option" v-model="model.subject">
                                                <option value="FAQ">FAQ</option>
                                                <option value="Sujet 1">Sujet 1</option>
                                                <option value="Sujet">Sujet</option>

                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <hr class="my-4" />
                                <!-- Description -->
                                <div class="pl-lg-4">
                                    <div class="form-group">
                                        <base-input alternative=""
                                                    :label="$t('contactsPage.labels.message')">
                                            <textarea rows="4" class="form-control form-control-alternative" placeholder="Message" v-model="model.message"></textarea>
                                        </base-input>
                                    </div>
                                </div>
                                <base-button style="background-color:#cc000e;border:none" block @click="sendContact">{{$t('contactsPage.buttons.send')}}</base-button>
                            </form>
                        </template>
                    </card>
                </div>
            </div>
        </div>
          <div class="container-fluid mt-5">
            <div class="row">
                <div class="col">
                    <ContactsTable :title="$t('contactsPage.tableTitle')" :data="contacts"></ContactsTable>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
 import ContactsTable from './Tables/ContactsTable'
  export default {

    name: 'contact',
      components: {
      ContactsTable
    },
    data() {
      return {
        model:{
            username:this.$store.state.doctor.user.firstName + " " + this.$store.state.doctor.user.lastName,
            email:this.$store.state.doctor.user.email ,
            phone:this.$store.state.doctor.user.phoneNumber ,
            subject:"FAQ",
            message:""
        },
        contacts:[]
      }
    },
    methods:{
        async sendContact(){
            console.log(this.model)
            try{
                const res = await this.$store.dispatch('doctor/sendContact',this.model)
                console.log(res);
                this.getContacts()
                this.$toast.open({
                message: "Message envoyé",
                type: "success",
              });
            }
            catch(err){
                console.log(err);
                this.$toast.open({
            message: "Erreur lors de l'envoi",
            type: "error",
          });
            }
        },
        async getContacts(){
            console.log(this.$store.state.doctor.user)
            try{
                this.contacts = await this.$store.dispatch('doctor/getContacts')
                console.log(this.contacts);
            }
            catch(err){
                console.log(err);
            }
        }
    },
    mounted(){
        this.getContacts()
    }
  };
</script>
<style>
.bg-gradient-doc{
    background:linear-gradient(87deg, #cc000e 0, #889720 100%) !important
}
.subject-label{
    color: #82846D;
    font-size: 0.875rem;
    font-weight: 600;
    display: inline-block;
    margin-bottom: 0.5rem;
}
.subject-option{
display: block;
    width: 100%;
    height: calc(1.5em + 1.25rem + 2px);
    padding: 0.625rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #8898aa;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #cad1d7;
    border-radius: 0.375rem;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
    border: 0;
}
</style>
