<template>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
            {{ title }}
          </h3>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table
        class="table align-items-center table-flush"
        :class="type === 'dark' ? 'table-dark' : ''"
        :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
        tbody-classes="list"
        :data="tableData"
      >
        <template slot="columns">
          <th class="text-center">{{$t('guestsPage.tableFields.name')}}</th>
          <th class="text-center">{{$t('guestsPage.tableFields.surname')}}</th>
          <th class="text-center">{{$t('guestsPage.tableFields.sexe')}}</th>
          <th class="text-center">{{$t('guestsPage.tableFields.phone')}}</th>
          <th class="text-center">{{$t('guestsPage.tableFields.email')}}</th>
          <th></th>
        </template>

        <template v-slot="{ row }">
          <th class="text-center">{{ row.lastName }}</th>
          <th class="text-center">{{ row.firstName }}</th>
          <th class="text-center">{{ row.gender }}</th>
          <th class="text-center">{{ row.phoneNumber }}</th>
          <th class="text-center">{{ row.email }}</th>
        </template>
      </base-table>
    </div>

    <!-- <div
      class="card-footer d-flex justify-content-end"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <base-pagination></base-pagination>
    </div> -->
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "projects-table-patient",
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  data() {
    return {
      verif: false,
      modals: {
        modal: false,
      },
      tableData: [],
    };
  },  created() {
    axios
      .get("https://algedoc-backend.herokuapp.com/api/guests/")
      .then((response) => {
        this.tableData = response.data
      })
      .catch((error) => {
        console.log("get all guests");
        console.log(error);
      });
  }
};
</script>
<style>
.details-fiche {
  padding: 5%;
  background: #82846d;
}
.details-fiche .form-control-label {
  color: #fff !important;
}

.description-fiche {
  display: block;
  width: 100%;
  /* height: calc(1.5em + 1.25rem + 2px); */
  padding: 0.625rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #8898aa;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #cad1d7;
  border-radius: 0.375rem;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
</style>
