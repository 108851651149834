<template>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
            {{ title }}
          </h3>
        </div>
        <!-- <div class="col text-right">
          <input class="form-control" placeholder="Recherche" />
        </div> -->
      </div>
    </div>

    <div class="table-responsive">
      <base-table
      v-if="data && data.length"
        class="table table-flush"
        :class="type === 'dark' ? 'table-dark' : ''"
        :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
        tbody-classes="list"
        :data="data"
        style="min-height: 25vh"
      >
        <template slot="columns">
          <th class="text-center">{{$t('contactsPage.tableFields.date')}}</th>
          <th class="text-center">{{$t('appointmentsPage.tableFields.email')}}</th>
          <th class="text-center">{{$t('appointmentsPage.tableFields.phone')}}</th>
          <th class="text-center">{{$t('contactsPage.tableFields.message')}}</th>
          <th class="text-center">{{$t('contactsPage.tableFields.response')}}</th>
          <!-- <th></th> -->
        </template>

        <template v-slot="{ row }">
          <th scope="row" class="text-center">20/12/2020</th>
          <td class="text-center">{{user.email}}</td>

          <td class="text-center">{{user.phoneNumber}}</td>

          <td class="text-center">
            {{ row.message }}
          </td>
          <td class="text-center">{{row.response}}</td>
          <!-- <td class="text-center">
            <base-dropdown class="dropdown" position="right">
              <a
                slot="title"
                class="btn btn-sm btn-icon-only text-light"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="fas fa-ellipsis-v"></i>
              </a>

              <template>
                <a class="dropdown-item" href="#" @click="modals.modal = true"
                  >Voir plus de detail</a
                >
                <a class="dropdown-item" href="#">Supprimer</a>
              </template>
            </base-dropdown>
          </td> -->
        </template>
      </base-table>
      <div v-else class="p-4">
        <h3>Pas de contacts</h3>
      </div>
    </div>

    <!-- <modal :show.sync="modals.modal">
      <h2 slot="header" class="modal-title" id="modal-title-default">
        Message docteur
      </h2>
      <div class="row">
        <div class="col-lg-12">
          <base-input
            alternative=""
            label="Nom & prénom"
            placeholder="Nom & prénom"
            input-classes="form-control-alternative"
          />
        </div>
        <div class="col-lg-6">
          <base-input
            alternative=""
            label="Téléphone du docteur"
            placeholder="Téléphone du docteur"
            input-classes="form-control-alternative"
          />
        </div>
        <div class="col-lg-6">
          <base-input
            alternative=""
            label="Email du docteur"
            placeholder="Email du docteur"
            input-classes="form-control-alternative"
          />
        </div>
      </div>
      <hr style="height: 1px; background: #cc000e" />
      <div class="row">
        <div class="col-lg-12 text-right btn-table-blog">
          <div class="form-group">
            <textarea
              rows="4"
              class="form-control form-control-alternative"
              placeholder="Messager..."
            >
Messager...</textarea
            >
          </div>
          <div class="form-group">
            <textarea
              rows="4"
              class="form-control form-control-alternative"
              placeholder="Messager..."
            >
Messager...</textarea
            >
          </div>
        </div>
      </div>
    </modal> -->

    <!-- <div
      class="card-footer d-flex justify-content-end"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <base-pagination></base-pagination>
    </div> -->
  </div>
</template>
<script>
export default {
  props: {
    type: {
      type: String,
    },
    data: Array,
    title: String,
  },
  data() {
    return {
      verif: false,
      modals: {
        modal: false,
      },
    };
  },
  computed:{
    user(){
      return this.$store.state.doctor.user
    }
  }
};
</script>
<style>
.details-fiche {
  padding: 5%;
  background: #82846d;
}
.details-fiche .form-control-label {
  color: #fff !important;
}

.description-fiche {
  display: block;
  width: 100%;
  /* height: calc(1.5em + 1.25rem + 2px); */
  padding: 0.625rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #8898aa;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #cad1d7;
  border-radius: 0.375rem;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
</style>
