<template>
  <div>
    <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
      <span class="mask bg-gradient-doc opacity-8"></span>
    </base-header>
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-12">
          <card shadow type="secondary">
            <div slot="header" class="bg-white border-0">
              <div class="row align-items-center">
                <div class="col-8">
                  <h3 class="mb-0">{{ $t("changePassword.title") }}</h3>
                </div>
              </div>
            </div>
            <div>
              <form @submit.prevent>
                <div class="row">
                  <div class="col-lg-4">
                    <base-input
                      alternative=""
                      :placeholder="$t('changePassword.fields[0]')"
                      type="password"
                      :label="$t('changePassword.fields[0]')"
                      input-classes="form-control-alternative"
                      v-model="password.cpassword"
                    />
                  </div>
                  <div class="col-lg-4">
                    <base-input
                      alternative=""
                      :placeholder="$t('changePassword.fields[1]')"
                      type="password"
                      :label="$t('changePassword.fields[1]')"
                      v-model="password.password"
                      input-classes="form-control-alternative"
                    />
                  </div>
                  <div class="col-lg-4">
                    <base-input
                      alternative=""
                      type="password"
                      :placeholder="$t('changePassword.fields[2]')"
                      :label="$t('changePassword.fields[2]')"
                      input-classes="form-control-alternative"
                      v-model="password.confirmPassword"
                    />
                  </div>
                  <div
                    class="offset-5"
                    style="font-size: 20px; color: red"
                    v-if="error && error.length > 0"
                  >
                    {{ error }}
                  </div>
                </div>
                <div class="row">
                  <!-- col-md-2 col-sm-12 col-12 -->
                  <base-button
                    style="background-color: #cc000e; border: none"
                    class="mt-5 ml-auto col-md-4"
                    block
                    @click="updatePassword"
                    >{{ $t("save") }}</base-button
                  >
                </div>
              </form>
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data: () => ({
    password: {
      cpassword: "",
      password: "",
      confirmPassword: "",
    },
    error: "",
  }),
  methods: {
    async updatePassword() {
      this.verifyData();
      if (!this.error)
        try {
          const res = await this.$store.dispatch(
            "doctor/updatePassword",
            this.password
          );
          console.log(res);
          this.$toast.open({
            message: "Mot de passe modifié",
            type: "success",
          });
        } catch (err) {
          console.log(err);
          this.$toast.open({
            message: "Erreur lors de la modification",
            type: "error",
          });
        }
    },
    verifyData() {
      if (this.password.password != this.password.confirmPassword)
        this.error = "le mot de passe ne convient pas";
      else this.error = null;
    },
  },
};
</script>
<style>
.bg-gradient-doc {
  background: linear-gradient(87deg, #cc000e 0, #889720 100%) !important;
}
.subject-label {
  color: #82846d;
  font-size: 0.875rem;
  font-weight: 600;
  display: inline-block;
  margin-bottom: 0.5rem;
}
.subject-option {
  display: block;
  width: 100%;
  height: calc(1.5em + 1.25rem + 2px);
  padding: 0.625rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #8898aa;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #cad1d7;
  border-radius: 0.375rem;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
  border: 0;
}
</style>
