<template>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
            {{ title }}
          </h3>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table
        v-if="data && data.length"
        class="table table-flush"
        :class="type === 'dark' ? 'table-dark' : ''"
        :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
        tbody-classes="list"
        :data="data"
        style="min-height: 25vh"
      >
        <template slot="columns">
          <th class="text-center">
            {{ $t("appointmentsPage.tableFields.date") }}
          </th>
          <th class="text-center">
            {{ $t("appointmentsPage.tableFields.name") }}
          </th>
          <th class="text-center">
            {{ $t("appointmentsPage.tableFields.sexe") }}
          </th>
          <th class="text-center">
            {{ $t("appointmentsPage.tableFields.phone") }}
          </th>
          <th class="text-center">
            {{ $t("appointmentsPage.tableFields.email") }}
          </th>
          <th></th>
        </template>

        <template v-slot="{ row }">
          <td class="text-center">
            {{ getDate(row.date) }}
          </td>
          <td class="text-center">
            {{ row.patientId.firstName }}
            {{ row.patientId.lastName }}
          </td>

          <td class="text-center">
            {{ row.patientId.gender }}
          </td>
          <td class="text-center">
            {{ row.patientId.phoneNumber }}
          </td>
          <td class="text-center">
            {{ row.patientId.email }}
          </td>
          <td class="text-center">
            <base-dropdown class="dropdown" position="right">
              <a
                slot="title"
                class="btn btn-sm btn-icon-only text-light"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="fas fa-ellipsis-v"></i>
              </a>
                <template  v-if="$props.type == 'hold'">
                <a
                  class="dropdown-item"
                  href="#"
                  @click="acceptAppointment(row._id)"
                  >{{ $t("appointmentsPage.tableActions.accept") }}</a
                >
                <a
                  class="dropdown-item"
                  href="#"
                  @click="declineAppointment(row._id)"
                  >{{ $t("appointmentsPage.tableActions.decline") }}</a
                >
              </template>
              <template  v-if="$props.type != 'hold'">
                <a
                  v-if="$props.type == 'refused'"
                  class="dropdown-item"
                  href="#"
                  @click="acceptAppointment(row._id)"
                  >{{ $t("appointmentsPage.tableActions.accept") }}</a
                >
                <a
                  v-if="$props.type == 'accepted'"
                  class="dropdown-item"
                  href="#"
                  @click="declineAppointment(row._id)"
                  >{{ $t("appointmentsPage.tableActions.decline") }}</a
                >
              </template>
            </base-dropdown>
          </td>
        </template>
      </base-table>
      <div v-else class="p-4">
        <h4 class="text-center">{{ $t("appointmentsPage.empty") }}</h4>
      </div>
    </div>

    <!-- <div
      class="card-footer d-flex justify-content-end"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <base-pagination total="30"></base-pagination>
    </div> -->
  </div>
</template>
<script>
export default {
  name: "appointment-table",
  props: {
    type: {
      type: String,
    },
    type: String,
    data: Array,
    title: String,
  },
  created() {
    console.log("test", this.$props.title);
  },
  methods: {
    getDate(date) {
      return new Date(date.day).toLocaleDateString();
    },
    async acceptAppointment(appointmentId) {
      try {
        const appointment = await this.$store.dispatch(
          "doctor/acceptAppointment",
          appointmentId
        );
        this.$emit("refresh");
        this.$toast.open({
          message: "RDV accepté",
          type: "success",
        });
        console.log(appointment);
        this.$store.dispatch("socket/acceptAppointmentNotification", {
          patientId: appointment.patientId,
        });
      } catch (err) {
        console.log(err);
        this.$toast.open({
          message: "Erreur lors de l'acceptation",
          type: "error",
        });
      }
    },
    async declineAppointment(appointmentId) {
      try {
        const appointment = await this.$store.dispatch(
          "doctor/declineAppointment",
          appointmentId
        );
        console.log(appointment);
        this.$emit("refresh");
        this.$toast.open({
          message: "RDV refusé",
          type: "success",
        });
        this.$store.dispatch("socket/declineAppointmentNotification", {
          patientId: appointment.patientId,
        });
      } catch (err) {
        console.log(err);
        this.$toast.open({
          message: "Erreur lors de la refus",
          type: "error",
        });
      }
    },
    // calculateAge(birthday) {
    //   // birthday is a date
    //   var ageDifMs = Date.now() - birthday.getTime();
    //   var ageDate = new Date(ageDifMs); // miliseconds from epoch
    //   return Math.abs(ageDate.getUTCFullYear() - 1970);
    // },
  },
};
</script>
<style></style>
