import axios from "axios";

export default {
  name: "user-profile",
  data() {
    return {
      offreYear: 'offreYear',
      offreMonth: 'offreMonth',
      priceToDisplay: 0,
      modal: false,
      voucherOffre: '',
      offerId: 0,
      chosen: 'mois',
      couponDuration: null,
      couponVerified: false,
      offreData: {}
      // offersData: [
      //   {
      //     name: "Gratuit",
      //     price: "0",
      //     duration: "mois",
      //     features: [
      //       "Gestion du calendrier",
      //       "Gestion des rendez-vous",
      //       "Statistique sur les rendez-vous",
      //     ]
      //   },
      //   {
      //     name: "Premium",
      //     price: "1",
      //     duration: "an",
      //     features: [
      //       "Statistique sur les rendez-vous",
      //       "Gestion du calendrier",
      //       "Gestion des rendez-vous",
      //       "Chat avec l'administrateur",
      //     ]
      //   },
      //   {
      //     name: "Gratuit",
      //     price: "0",
      //     duration: "an",
      //     features: [
      //       "Gestion du calendrier",
      //       "Gestion des rendez-vous",
      //       "Statistique sur les rendez-vous",
      //     ]
      //   },
      //   {
      //     name: "Premium",
      //     price: "0",
      //     duration: "mois",
      //     features: [
      //       "Statistique sur les rendez-vous",
      //       "Gestion du calendrier",
      //       "Gestion des rendez-vous",
      //       "Chat avec l'administrateur"
      //     ]
      //   },
      // ],
      // offers: []
    };
  },
  created() {
    this.getOffers()
  },
  methods: {
    offerDoctor() {
      axios
        .put(
          "https://algedoc-backend.herokuapp.com/api/vouchers/", {
          voucherCode: this.voucherOffre,
          userId: localStorage.getItem('algerieDok-U')
        }
        )
        .then((response) => {
          this.$toast.open({
            message: "Offre bien activé",
            type: "success",
          });
          this.couponDuration = response.data.duration
          this.couponVerified = true
          this.getOffers()
        })
        .catch((error) => {
          this.$toast.open({
            message: "Erreur lors de l'activation'",
            type: "error",
          });
        });
    },
    choosePeriod(choice) {
      this.chosen = choice
    },
    getOffers() {
      // GET REQUEST
      // this.switchOffers()
      axios
        .get(
          "https://algedoc-backend.herokuapp.com/api/offers/",
        )
        .then((response) => {
          this.offreData = response.data[0]
          this.offerId = response.data[0]._id
          if (this.chosen == "an") {
            console.log(response)
            this.priceToDisplay = (response.data[0].price * 12) - (response.data[0].price * 12) * response.data[0].percentage / 100
          } else {
            this.priceToDisplay = response.data[0].price
          }

        })
        .catch((error) => {
        });
    },
    // switchOffers() {
    //   this.offers = new Array();
    //   this.offersData.forEach(element => {
    //     if (element.duration == this.chosen) {
    //       this.offers.push(element)
    //     }
    //   });
    //   this.offers.sort(function compare(a, b) {
    //     if (a.price < b.price)
    //       return -1;
    //     if (a.price > b.price)
    //       return 1;
    //     return 0;
    //   })
    // }
  }
};